<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Edit'">
      <template v-slot:title>
        <h3 class="pt-1 card-label text-primary">
          {{ $t("MENU.ITEM.SRV.SERVICES") }} Обект:
          {{ location_info.locationName }}
        </h3>
      </template>

      <template v-slot:body>
        <div>
          <label class="typo__label">Списък услуги</label>
          <multiselect
            v-model="value"
            :options="options"
            :blockKeys="['Delete']"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Изберете от списъка услуги"
            label="name"
            track-by="name"
            :preselect-first="false"
          >
            <template slot="selection" slot-scope="{ values, search, isOpen }"
              ><span
                class="multiselect__single"
                v-if="values.length &amp;&amp; !isOpen"
                >{{ values.length }} избрани услуги</span
              ></template
            >
          </multiselect>
          <!-- <pre class="language-json"><code>{{ value  }}</code></pre> -->
        </div>

        <form>
          <div class="row" v-if="value" v-for="item in value" :key="item.id">
            <div class="col-10">
              <b-form-input
                v-model="item.name"
                placeholder="Enter your name"
                disabled
              ></b-form-input>
            </div>
            <div class="col-2">
              <b-form-input
                v-model="item.price"
                placeholder="Цена"
              ></b-form-input>
            </div>
          </div>
        </form>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingService"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import { FETCH_SERVICES } from "@/modules/services/store/service.module";
import {
  FETCH_LOCATION_SERVICES,
  UPDATE_LOCATION_SERVICES,
} from "@/modules/assignorslocation/store/location-service.module";

import { FETCH_ASSIGNOR_LOCATION } from "@/modules/assignorslocation/store/location.module";
import FormBuilder from "@/view/content/forms/FormBuilder.vue";
import FormInput from "@/view/content/forms/components/FormInput.vue";
import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";

import { validationMixin } from "vuelidate";

import { formBuilderMixin } from "@/core/mixins/formBuilderMixin.js";

import KTCard from "@/view/content/Card.vue";
import { mapGetters, mapState } from "vuex";

export default {
  mixins: [validationMixin, formBuilderMixin],
  data() {
    return {
      errors: {},
      id: null,
      all_services: null,
      value: null,
      options: [],
      location_info: "",
      item: [],
    };
  },
  components: {
    KTCard,
    FormBuilder,
    SaveButtonDropdown,
    FormInput,
    Multiselect,
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$i18n.t("MENU.ITEM.ASL.ASSIGNORS_LOCATIONS"),
        route: { name: "list-assignor-locations" },
      },
      { title: this.$i18n.t("MENU.ITEM.SRV.SERVICES") },
      { title: this.$i18n.t("MENU.ITEM.EDIT") },
    ]);

    this.$store.dispatch(FETCH_SERVICES).then((data) => {
      this.all_services = data.data;
      this.options = data.data.items;
    });

    let id = this.$route.params.id;
    this.id = this.$route.params.id;

    this.$store.dispatch(FETCH_LOCATION_SERVICES, id).then((data) => {
      this.value = data.data;
    });

    this.$store.dispatch(FETCH_ASSIGNOR_LOCATION, id).then((data) => {
      this.location_info = data.data;
    });
  },
  computed: {
    ...mapGetters(["isLoadingService", "isLoadingSharedStore"]),
    ...mapState({
      errors: (state) => state.errors,
    }),
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
    },
    onSave(next) {
      let vm = this;
      let services_list = { services: this.value };

      //console.log(services_list)
      this.$store
        .dispatch(UPDATE_LOCATION_SERVICES, {
          id: this.id,
          payload: services_list,
        })
        .then((data) => {
          vm.errors = data.data.errors;
          let itemEdit = data.data;
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message,
          });
          if (next == "new") {
            //New
            this.$router.push({ name: "add-services" });
          }
          if (next == "exit") {
            this.$router.push({ name: "list-services" });
          }
        })
        .catch((response) => {
          vm.errors = response.data.errors;
          vm.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response.data.message,
          });
        });
    },
    resetVForm() {
      this.$v.$reset();
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
